<template>
  <ValidationProvider v-slot="{ failed, errors }" rules="required" :name="name">
    <div class="form-group" :class="{'has-error': errors.length, 'valid': !errors.length }">
      <div class="input-group has-margin-top">
        <multiselect
          v-model="iValue"
          :options="options"
          track-by="value"
          label="label"
          :close-on-select="true"
          :show-labels="false"
          :searchable="false"
          :multiple="false"
          :allow-empty="false"
          :preselect-first="false"
        ></multiselect>

        <label class="control-label" :for="name">
          {{ label }}
        </label>

        <i class="bar"></i>
        <small v-show="errors.length" class="help text-danger">{{ errors[0] }}</small>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>

export default {
  props: {
    value: {
      required: false,
      default: undefined,
    },
    name: {
      type: String,
      default: 'Timezone',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: [Array],
      default: () => [],
    },
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },

      set(newValue) {
        this.$emit('input', newValue )
      },
    },
  },
}
</script>
